/*-----------------------------------------------------------------------------------

    Template Name: Sample - Corporate Business Bootstrap4 HTML5 Template
    Template URI: site.com
    Description: Sample - Corporate Business Bootstrap4 HTML5 Template
    Author: MD THAHERIL ISLAM
    Author URI: site.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/

@import "variables";
@import "mixin";
@import "common";
@import "header";
@import "hero";
@import "features";
@import "capture";
@import "faq";
@import "intro";
@import "testimonial";
@import "download";
@import "screenshot";
@import "news";
@import "footer";

// Custom
.logo {
  img {
    height: 50px;
    @media (min-width: 992px) {
      height: 80px;
    }
  }
}

.pagenotfound {
  text-align: center;
  padding: 120px 20px;
  h1 {
    font-size: 60px;
    margin-bottom: 30px;
  }
  h2 {
    margin-bottom: 20px;
    color: $yellow;
  }
}
section {
  &.conference {
    .section-title {
      h5 {
        color: $yellow;
      }
    }
    .conferencedesc {
      p {
        margin-bottom: 20px;
        text-align: justify;
      }
    }
    .submitcontainer {
      text-align: center;
    }
    .successmessg {
      p {
        text-align: center;
        color: $yellow;
      }
    }
  }
}
