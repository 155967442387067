/*===========================
    6.FAQ css
===========================*/

.faq-area {
  padding-bottom: 120px;
  position: relative;
  z-index: 9;
  padding-top: 112px;
  @media (max-width: 992px) {
    padding: 50px 0 50px 0;
  }
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: -400px;
    height: 920px;
    width: 89%;
    background-image: url(../assets/images/capture-bg.jpg);
    background-size: 100% 100%;
    z-index: -1;
    @media #{$laptop} {
      background-size: cover;
    }
    @media #{$xs} {
      width: 100%;
    }
  }
  &::after {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    height: 1050px;
    width: 90%;
    background: $heading-color;
    z-index: -2;
    @media #{$laptop} {
      width: 100%;
    }
    @media #{$xs} {
      width: 100%;
    }
  }
  & .faq-shape {
    position: absolute;
    left: 0;
    top: 420px;
    height: 600px;
    width: 100%;
    background-image: url(../assets/images/bg-shape.png);
    opacity: 0.5;
    background-size: 100% 100%;
    z-index: -3;
  }
}

.faq-box {
  background: $white;
  padding: 60px;
  position: relative;
  z-index: 10;
  @media #{$lg} {
    padding: 27px 30px 84px;
  }
  @media #{$md} {
    padding: 30px;
  }
  @media #{$xs} {
    padding: 20px 0;
  }
  @media (max-width: 992px) {
    margin: 0 10px;
    box-shadow: none;
  }
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url(../assets/images/faq-bg.jpg);
    background-size: cover;
  }
}

.faq-accrodion {
  @media #{$md} {
    margin-top: 30px;
    margin-right: 0;
  }
  @media #{$xs} {
    margin-right: 0;
    padding: 0 20px;
  }
  & .accrodion {
    position: relative;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 35px;
      font-weight: 700;
      color: black;
    }
    & + .accrodion {
      margin-top: 10px;
    }

    & .accrodion-inner {
      background-color: transparent;
      padding: 12px 0;
      position: relative;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      border-radius: 0px;
      @media (max-width: 992px) {
        padding-left: 30px;
      }
    }
    & .accrodion-title {
      padding: 0px 32px;
      cursor: pointer;
      position: relative;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      @media #{$xs} {
        padding: 0 38px 0 30px;
      }
      &::before {
        content: "\f13a";
        font-size: 20px;
        font-weight: 400;
        color: $theme-color;
        text-align: center;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        opacity: 1;
        -webkit-transition: all 500ms ease;
        transition: all 500ms ease;
        font-family: "Font Awesome 5 Pro";
        @media #{$xs} {
          justify-content: flex-end;
        }
      }
      &::after {
        content: "\f139";
        font-weight: 400;
        font-size: 20px;
        color: $theme-color;
        text-align: center;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        opacity: 0;
        -webkit-transition: all 500ms ease;
        transition: all 500ms ease;
        font-family: "Font Awesome 5 Pro";
        @media #{$xs} {
          justify-content: flex-end;
        }
      }
      & h4 {
        margin: 0;
        color: $black;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0;
        @media #{$xs} {
          font-size: 17px;
        }
      }
    }
    & .accrodion-content {
      & .inner {
        padding: 0 38px;
        padding-right: 70px;
        padding-top: 29px;
        padding-bottom: 0px;
        @media (max-width: 992px) {
          padding-top: 0;
          padding-left: 32px;
        }
      }
      & p {
        margin: 0;
        font-size: 14px;
        line-height: 30px;
        font-weight: 500;
        color: black;
      }
    }
  }
}

.faq-play-extra {
  position: relative;
  display: none;
  @media #{$xs} {
    display: block;
  }
  @media (max-width: 992px) {
    margin: 0 10px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  & a {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    background: $theme-color;
    border-radius: 50%;
    color: $white;
    font-size: 14px;
  }
}
