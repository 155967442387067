/*===========================
    11.NEWS css
===========================*/

.news-area {
  padding-bottom: 160px;
  position: relative;
  z-index: 11;
  padding-top: 113px;
  @media (max-width: 992px) {
    padding: 50px 0 40px 0;
  }
  & .section-title {
    padding-bottom: 22px;
  }
  &.news-page-area {
    position: relative;
    padding-bottom: 120px;
    z-index: 9;
    @media (max-width: 992px) {
      padding-bottom: 50px;
    }
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      height: 547px;
      width: 100%;
      background-image: url(../assets/images/download-bg.jpg);
      background-size: cover;
      background-repeat: no-repeat;
    }
    & .news-active {
      & .slick-arrow {
        position: absolute;
        top: -76px;
        font-size: 40px;
        color: $heading-color;
        @include transition(0.3s);
        z-index: 99;
        cursor: pointer;
        &.prev {
          right: 70px;
        }
        &.next {
          right: 15px;
        }
      }
    }
  }
}

.news-item {
  & .news-thumb {
    overflow: hidden;
    & img {
      width: 100%;
      @include transition(0.3s);
    }
  }
  & .news-content {
    background: $white;
    padding: 50px 46px 57px;
    .divider {
      margin: 0 6px;
    }
    @media #{$xs} {
      padding: 25px;
    }
    & a {
      &:hover {
        & .title {
          color: $theme-color;
        }
      }
    }
    & .title {
      font-size: 20px;
      line-height: 30px;
      color: $black;
      @include transition(0.3s);
      @media #{$lg} {
        font-size: 16px;
      }
    }
    & span {
      font-size: 12px;
      font-weight: 500;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    & p {
      font-size: 14px;
      line-height: 30px;
      font-weight: 500;
    }
  }
  &:hover {
    & .news-thumb {
      & img {
        transform: scale(1.1);
      }
    }
  }
}

.blog-area {
  position: relative;
  z-index: 10;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 360px;
    background-image: url(../assets/images/bg-shape.png);
    background-size: cover;
    z-index: -1;
    opacity: 0.6;
  }
  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 360px;
    background-image: url(../assets/images/bg-shape-2.png);
    background-size: cover;
    z-index: -1;
    opacity: 0.6;
  }
}

.blog-area {
  & .blog-thumb {
    & img {
      width: 100%;
      // box-shadow: 0px 10px 30px 0px rgba(71, 75, 76, 0.2);
    }
  }
  &.single-blog-area {
    &::after {
      display: none;
    }
  }
}

.single-blog-top-content {
  padding-top: 55px;
  position: relative;
  & ul {
    position: absolute;
    left: -100px;
    top: 39%;
    transform: translateX(-50%);
    @media #{$md} {
      display: none;
    }
    & li {
      & a {
        height: 48px;
        width: 48px;
        text-align: center;
        line-height: 48px;
        background: $white;
        border-radius: 50%;
        box-shadow: 0px 10px 10px 0px rgba(255, 190, 0, 0.2);
        color: $theme-color;
        @include transition(0.3s);
        margin-bottom: 20px;
        &:hover {
          background: $heading-color;
          box-shadow: 0px 10px 20px 0px rgba(14, 112, 80, 0.2);
        }
      }
    }
  }
  & .title {
    font-size: 60px;
    line-height: 80px;
    @media #{$lg} {
      font-size: 50px;
      line-height: 65px;
    }
    @media #{$md} {
      font-size: 50px;
      line-height: 65px;
    }
    @media #{$xs} {
      font-size: 32px;
      line-height: 45px;
    }
    @media #{$sm} {
      font-size: 40px;
      line-height: 55px;
    }
  }
  & > span {
    font-size: 14px;
    font-weight: 500;
    color: #6b6b6b;
    padding-top: 23px;
    padding-bottom: 30px;
    .divider {
      margin: 0 6px;
    }
  }
  & p {
    padding-bottom: 25px;
    font-size: 16px;
    line-height: 30px;
  }
}

.single-blog-content {
  padding-top: 55px;
  padding-right: 170px;
  padding-left: 30px;
  @media #{$lg} {
    padding-right: 0;
  }
  @media #{$md} {
    padding-right: 0;
    padding-left: 0;
  }
  @media #{$xs} {
    padding-right: 0;
    padding-left: 0;
  }
  & p {
    font-size: 16px;
    line-height: 30px;
  }
}

.single-blog-content-2 {
  padding-top: 47px;
  & .title {
    font-size: 30px;
    font-weight: 700;
    padding-top: 28px;
    padding-bottom: 30px;
  }
}

.blog-comment-area {
  padding-top: 117px;
  padding-bottom: 120px;
  position: relative;
  z-index: 10;
  @media (max-width: 992px) {
    padding: 50px 0 50px 0;
  }
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 360px;
    background-image: url(../assets/images/bg-shape-2.png);
    background-size: cover;
    z-index: -1;
    opacity: 0.6;
    transform: rotate(180deg);
  }
  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -360px;
    width: 100%;
    height: 580px;
    background-image: url(../assets/images/bg-shape-2.png);
    background-size: cover;
    z-index: -1;
    opacity: 0.6;
  }
}

.blog-comment-title {
  padding-bottom: 60px;
  & .title {
    font-size: 30px;
  }
  @media (max-width: 992px) {
    padding-bottom: 20px;
  }
}

.blog-comment-item {
  & .item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-bottom: 10px;
    @media #{$xs} {
      display: block;
      margin: 30px 0;
    }
    @media #{$sm} {
      display: flex;
      margin: 0 0 10px;
    }
    @media (max-width: 992px) {
      padding: 0 10px;
    }
    & .thumb {
      min-width: 100px;
    }
    & .box {
      padding: 45px;
      background: $white;
      box-shadow: 0px 30px 50px 0px rgba(255, 190, 0, 0.1);
      margin-left: 20px;
      position: relative;
      @media #{$xs} {
        margin-left: 0;
        margin-top: 20px;
        padding: 20px;
      }
      @media #{$sm} {
        margin-left: 20px;
        margin-top: 0;
      }
      & a {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0 22px;
        height: 40px;
        background: #fffbee;
        border-radius: 40px;
        color: $heading-color;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 43px;
        @include transition(0.3s);
        // @media #{$xs} {
        // 	position: static;
        // 	margin-top: 20px;

        // }
        @media #{$sm} {
          position: absolute;
          margin-top: 0;
        }
        &:hover {
          background: $theme-color;
        }
      }
      & .title {
        font-size: 18px;
        color: $black;
      }
      & span {
        font-size: 12px;
        font-weight: 500;
        padding-top: 6px;
        padding-bottom: 14px;
      }
      & p {
        font-size: 14px;
        line-height: 30px;
      }
    }
    &.item-2 {
      margin-left: 110px;
      // @media #{$xs} {
      // 	margin-left: 0px;
      // }
      @media #{$sm} {
        margin-left: 50px;
      }
      @media (max-width: 992px) {
        margin-left: 25px;
      }
    }
  }
}

.blog-comment-form-area {
  & .input-box {
    & input {
      width: 100%;
      box-shadow: 0px 30px 50px 0px rgba(255, 190, 0, 0.1);
      height: 65px;
      border: 0;
      background: $white;
      padding-left: 30px;
      font-size: 14px;
      font-weight: 500;
      color: #6b6b6b;
      &::placeholder {
        opacity: 1;
        color: #6b6b6b;
      }
    }
    & textarea {
      width: 100%;
      box-shadow: 0px 30px 50px 0px rgba(255, 190, 0, 0.1);
      height: 215px;
      border: 0;
      background: $white;
      padding-left: 30px;
      padding-top: 20px;
      resize: none;
      font-size: 14px;
      font-weight: 500;
      color: #6b6b6b;
      &::placeholder {
        opacity: 1;
        color: #6b6b6b;
      }
    }
    & select {
      width: 100%;
      box-shadow: 0px 30px 50px 0px rgba(255, 190, 0, 0.1);
      height: 65px;
      border: 0;
      background: $white;
      padding-left: 30px;
      font-size: 14px;
      font-weight: 500;
      color: #6b6b6b;
    }
  }
}

.custom-row {
  margin-left: -5px;
  margin-right: -5px;
  & .col-lg-6 {
    padding-left: 5px;
    padding-right: 5px;
  }
}
