/*===========================
    1. COMMON css
===========================*/

@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@100;300;400;500;600;700;800;900&display=swap");

html,
body {
  width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: $spa;
  font-weight: normal;
  font-style: normal;
  color: $text-color;
  background: #fffbee;
}

* {
  margin: 0;
  padding: 0;
  @include box-sizing(border-box);
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $spa;
  font-weight: 700;
  color: $heading-color;
  margin: 0px;
}

h1 {
  font-size: 36px;
  @media (min-width: 992px) {
    font-size: 48px;
  }
}
h2 {
  font-size: 28px;
  @media (min-width: 992px) {
    font-size: 36px;
  }
}
h3 {
  font-size: 22px;
  @media (min-width: 992px) {
    font-size: 28px;
  }
}
h4 {
  font-size: 18px;
  @media (min-width: 992px) {
    font-size: 22px;
  }
}
h5 {
  font-size: 16px;
  @media (min-width: 992px) {
    font-size: 18px;
  }
}
h6 {
  font-size: 14px;
  @media (min-width: 992px) {
    font-size: 16px;
  }
}
ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: $text-color;
  margin: 0px;
  @media (min-width: 992px) {
    font-size: 16px;
  }
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.slick-slide {
  outline: 0;
}

.custom-container {
  @media #{$desktop} {
    max-width: 1500px !important;
  }
}

// others common css here

/*===== All Button Style =====*/

.main-btn {
  display: inline-block;
  position: relative;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  @include user-select(none);
  border: 0;
  padding: 0 68px 0 38px;
  font-size: 17px;
  height: 60px;
  line-height: 62.5px;
  border-radius: 40px;
  color: $heading-color;
  cursor: pointer;
  z-index: 5;
  @include transition(0.4s);
  letter-spacing: -0.02em;
  background-color: $yellow;
  // box-shadow: 0px 10px 30px rgba(255, 190, 0, 0.2);

  & i {
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
    @include transition(0.3s);
    // @media #{$lg} {
    //     padding-left: 35px;
    // }
  }

  &:hover {
    background-color: $heading-color;
    color: $white;
    border-color: $theme-color;
    // box-shadow: 0px 10px 30px rgba(39, 162, 122, 0.2);
    & i {
      color: $white;
    }
  }
  &:disabled {
    cursor: default;
    opacity: 0.8;
  }

  &.main-btn-2 {
    background-color: $heading-color;
    color: $white;
    // box-shadow: 0px 10px 30px rgba(39, 162, 122, 0.2);
    & i {
      color: $white;
    }
    &:hover {
      background-color: $theme-color;
      color: $heading-color;
      // box-shadow: 0px 10px 30px rgba(255, 190, 0, 0.2);
      & i {
        color: $heading-color;
      }
    }
  }
  &.main-btn-3 {
    background: $white;
    color: $heading-color;
    & i {
      color: $heading-color;
    }
    &:hover {
      background: $heading-color;
      color: $white;
      & i {
        color: $theme-color;
      }
    }
  }
  &.main-btn-4 {
    &:hover {
      background: $white;
      color: $heading-color;
      & i {
        color: $heading-color;
      }
    }
  }

  &.main-btn-5 {
    background: $theme-color;
    color: white;
    & i {
      color: white;
    }
    &:hover {
      background: $heading-color;
      color: $white;
      & i {
        color: $white;
      }
    }
  }
  @media (max-width: 992px) {
    width: 70%;
    margin-bottom: 10px;
  }
}

.section-title {
  padding-bottom: 9px;
  & .title {
    font-size: 50px;
    letter-spacing: -0.04em;
    line-height: 70px;
    color: $heading-color;
    @media #{$lg} {
      font-size: 38px;
      line-height: 50px;
    }
    @media #{$md} {
      font-size: 42px;
      line-height: 55px;
    }
    @media #{$xs} {
      font-size: 25px;
      line-height: 50px;
    }
    @media #{$sm} {
      font-size: 34px;
      line-height: 54px;
    }
    @media (max-width: 992px) {
      text-align: center;
      padding: 0 50px;
    }
  }
}
